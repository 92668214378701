<template>
  <label>
    <i v-if="required" class="text-danger bi bi-record-circle"></i>
    <span v-html="text" style="margin-left: 5px"></span>
  </label>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    }
  }
});
</script>
